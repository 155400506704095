.about-us-v1 {
	margin-top: var(--page-margin-top);

	/*	.inner-banner {
		background-image: url(RESOURCE/img/bg-wir.jpg);
	}  */
	.contact-form-v1 {
		@media (min-width: 992px) {
			margin-top: 160px;
		}

	}

	.sidebar {
		ul {
			@media(max-width:992px) {
				padding-top: 30px;
			}
		}

		h3 {
			@media(max-width:992px) {
				display: none;
			}
		}
	}

	.team-members {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(min(var(--about-us-grid-width), 100%), 1fr));
		grid-gap: 1em;
	}

	.sidebar {
		.about-img {
			display: block;

			@media(max-width:992px) {
				display: none;
			}
		}
	}

	.about-mobile-bl {
		.col-5 {
			display: none;

			@media(max-width:992px) {
				display: block;
			}
		}


		.about-img {
			@media(max-width:992px) {
				margin-bottom: 10px !important;
			}
		}

		h3 {
			font-size: 14px;
			text-align: center;
		}
	}

	.about-img {
		width: 100%;
		padding-top: 100%;
		border-radius: 50%;
		position: relative;
		top: 0;
		display: block;
		margin-bottom: 30px;
		overflow: hidden;
		height: 0;

		img {
			object-fit: cover;
			width: 100%;
			height: 115%;
			position: absolute;
			top: 0;
		}
	}

	.member-info {
		text-align: center;
		background-color: var(--color-white);

		.name {
			font-weight: 600;
		}

		.resort {
			font-size: var(--font-size-md);
			color: var(--color-grey-dark);
			margin-bottom: 10px;
		}

		.phone {
			font-size: var(--font-size-md);
		}

		.email {
			font-size: var(--font-size-md);
		}

	}


}