.contact-form-v1 {
	padding-bottom: 62px !important;
	border: 1px solid #ebebeb;
	border-radius: 10px;

	/* @media (min-width:1100px) {
		width: 80%;
	} */


	.checkbox label {
		vertical-align: inherit;
	}

	.red {
		color: var(--color-red);
	}
}