.unit-title-v1 {
	.stars {
		color: var(--color-stars);
		font-size: var(--font-size-md);
		vertical-align: top;
	}

	h1 {
		display: inline-block;
		margin-right: 15px;
		color: var(--main-nav-font-color);
	}

	.sub-heading {
		font-family: var(--font-family-main);
		font-size: 18px;
		max-width: 50%;
	}

	@media (min-width:1201px) {
		display: flex;
		align-items: center;
	}
}