.blog-card-v1 {
	margin-bottom: 20px;
	width: 100%;
	/*box-shadow: 0 0.1rem 1rem rgb(0 0 0 / .1);*/
	background-color: var(--color-white);
	padding: 10px;
	/* border: 1px solid var(--color-primary); */

	/*&:hover {
		-webkit-transform: translateX(1px);p
	}*/

	.image {
		height: 330px;
		overflow: hidden;
		position: relative;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	.blog-body {
		padding-top: 10px;
	}

	.date {
		font-size: 0.85rem;
		padding: 2px 5px;
		font-weight: bold;
	}

	.title {
		font-size: 28px;
		color: var(--blog-card-title-font-color);

		font-weight: bold;
		margin: 30px 0;
		line-height: 1.2;

		@media (max-width:767px) {
			margin: 20px 0;
		}
	}

	.link {
		position: absolute;
		bottom: 0;
		margin-top: 30px;

		a {
			text-decoration: underline;
			color: var(--color-primary);

			&:hover {
				text-decoration: none;
			}
		}

		@media (max-width:992px) {
			position: relative;
			margin-top: 20px;
		}
	}
}