.contact-sidebar-v1 {

	.contact-map {
		width: 100%;
		height: 300px;
	}

	.list-icon {
		.list-inline-item {
			position: relative;

			i {
				color: var(--color-white);
				background-color: var(--color-secondary);
				padding: 5px;
				width: 30px;
				height: 30px;
				border-radius: 50%;
				display: inline-flex;
				align-items: center;
				justify-content: center;
				position: absolute;
				left: 0;
				top: -20px;
				box-shadow: 0 0 10px rgba(0, 0, 0, .18);
				font-size: 19px;
			}
		}
	}

	.contact-bl {
		display: flex;
		margin-bottom: 5px;
		position: relative;
		padding-left: 40px;
		min-height: 40px;
		align-items: center;
		font-size: var(--font-size-md);

		&:last-child {
			margin-bottom: 0;
		}

		.contact-flex {
			display: flex;
		}

		a {
			color: var(--color-primary);

			&:hover {
				color: var(--color-primary);
			}
		}


		i.icon {
			color: var(--color-white);
			background-color: var(--color-secondary);
			padding: 5px;
			width: 30px;
			height: 30px;
			border-radius: 50%;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			position: absolute;
			left: 0;
			top: 2px;
			box-shadow: 0 0 10px rgba(0, 0, 0, .18);
			font-size: 16px;

			&.fa-whatsapp {
				left: 0;
			}

		}


	}

	.openings {
		div {
			margin-top: 10px;
			font-size: var(--font-size-md);

		}
	}
}