.unit-page-layout-v1 {
	margin-top: 140px;
	margin-bottom: 20px;
	padding: 0 15px;

	@media (max-width:1200px) {
		margin-top: 0;
	}

	.unit-title-v1 {
		@media(max-width:768px) {
			padding-top: 24px;
		}
	}

	.view-image-gallery {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		top: 0;
		z-index: 1;
		cursor: pointer;
	}



	.unit-gallery {
		margin-top: -30px;

		@media (max-width: 767px) {
			margin-top: 30px;
		}

		@media all and (min-width: 768px) and (max-width: 991px) {
			margin-top: 0;
		}

		.v-img-gallery {
			position: relative;
			width: 100%;
			padding-top: 2px;
			display: -webkit-inline-box;
			display: inline-flex;
			margin-bottom: 20px;
			padding-left: 5px;
			padding-right: 5px;
			background: #fff;

			@media (min-width:993px) {
				left: -15px;
			}

			.owl-item {
				a {
					width: 100%;
					padding: 0;
					box-shadow: 3px 3px 2px rgb(0 0 0/10%);
					border: 2px solid var(--color-white);
					position: relative;
					display: inline-block;

					img {
						border: none;
						padding: 0;
						width: 100%;
						border-radius: 0;
						height: 100%;
						-o-object-fit: cover;
						object-fit: cover;
						aspect-ratio: 3/2;
					}
				}
			}

			.owl-nav {
				position: absolute;
				top: 25px;
				left: 0;
				width: 100%;

				@media (max-width: 767px) {
					top: 10px;
				}

				.owl-prev {
					width: 40px;
					height: 40px;
					position: absolute;
					left: 0;
					outline: none;
					display: -webkit-box;
					display: flex;
					-webkit-box-align: center;
					align-items: center;
					-webkit-box-pack: center;
					justify-content: center;
					font-size: 0;

					@media (max-width:767px) {
						top: 15px;
					}


					span {
						font-size: 35px;
						color: #fff;
					}
				}

				.owl-next {
					width: 40px;
					height: 40px;
					position: absolute;
					right: 6px;
					left: auto;
					outline: none;
					display: flex;
					-webkit-box-align: center;
					align-items: center;
					-webkit-box-pack: center;
					justify-content: center;

					@media (max-width:767px) {
						top: 15px;
					}

					span {
						font-size: 35px;
						color: #fff;
					}
				}
			}
		}
	}

	.nav-tabs {
		display: flex;
		flex-wrap: wrap;
		padding-left: 0;
		margin-bottom: 0;
		list-style: none;

		@media (max-width:767px) {
			display: block;
		}

		li {
			flex: 1 1 auto;
			margin-right: 10px;

			@media (max-width:767px) {
				margin-right: 1px;
				margin-top: 8px;
				display: inline-block;
			}

			a {
				display: inline-block;
				color: var(--color-black);
				font-size: 16px;
				border-bottom: 2px solid transparent;

				@media (max-width:767px) {
					padding: .5rem .75rem;
					font-size: 14px;
					background-color: #efefef;
				}

				&:hover {
					text-decoration: none;
				}

				&.active {
					border-bottom: 2px solid;

					@media (max-width:767px) {
						background-color: var(--color-primary);
						color: var(--color-white);
						border-bottom: none;
					}
				}
			}
		}
	}

	.tab-content {
		.tab-pane {
			padding: 2rem 0;
		}
	}

	#prices {
		.tab-content {
			.tab-pane {
				padding-bottom: 0;
			}
		}
	}



	.unit-list-section {


		@media (min-width:768px) and (max-width:992px) {
			height: 500px;
		}

		@media (max-width:767px) {
			height: 350px;
		}

		.owl-item {


			&.active {
				.view-gallery-btn {
					display: block;
				}
			}

			@media (min-width:768px) and (max-width:992px) {
				height: 500px;
			}

			@media (max-width:767px) {
				height: 350px;
			}

			.item {
				height: 500px;
				display: block;

				@media (min-width:768px) and (max-width:992px) {
					height: 500px;
				}

				@media (max-width:767px) {
					height: 350px;
				}

				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
					object-position: bottom;
					aspect-ratio: 3/2;
				}
			}
		}

		.owl-nav {
			position: absolute;
			top: 42%;
			left: 0;
			width: 100%;

			.owl-prev {
				width: 40px;
				height: 40px;
				position: absolute;
				left: 0;
				outline: none;
				display: -webkit-box;
				display: flex;
				-webkit-box-align: center;
				align-items: center;
				-webkit-box-pack: center;
				justify-content: center;

				&:hover {
					span {
						font-size: 90px;
					}
				}

				span {
					font-size: 70px;
					color: #fff;


				}
			}

			.owl-next {
				width: 40px;
				height: 40px;
				position: absolute;
				right: 6px;
				left: auto;
				outline: none;
				display: flex;
				-webkit-box-align: center;
				align-items: center;
				-webkit-box-pack: center;
				justify-content: center;

				&:hover {
					span {
						font-size: 90px;
					}
				}

				span {
					font-size: 70px;
					color: #fff;
				}
			}
		}

		.view-gallery-btn {
			position: absolute;
			bottom: 40px;
			right: 15px;
			z-index: 1;
			cursor: pointer;
			display: none;
		}
	}


	.left-col {
		padding: 0px 15px;

		@media(max-width: 1200px) {
			width: 100% !important;
		}

		@media(min-width: 1201px) {
			width: 68% !important;
		}


		@media(min-width: 1500px) {
			width: 70% !important;
		}
	}

	.right-col {

		padding: 0px 15px;

		@media(max-width: 1200px) {
			display: none;
		}

		@media(min-width: 1201px) {
			width: 32% !important;
		}


		@media(min-width: 1500px) {
			width: 30% !important;
		}
	}


	.booking-hint {
		font-size: var(--font-size-md);
		margin-top: -20px;
		padding-bottom: 10px;
	}

	.unit-book-now-v3 {
		@media(min-width:1200px) {
			/* background-color: var(--color-grey-light); */
			padding: 20px 15px;
			position: -webkit-sticky;
			position: sticky;
			z-index: 12;
			top: 180px;
			display: none;
			margin-bottom: 57px;
		}

		&.is-visible {
			@media(min-width:992px) {
				display: block;
			}
		}
	}

	.unit-owner-text {
		/* border: 1px solid #ebebeb;
		border-radius: 10px; 
      padding: 15px; */
		border-top: 1px solid #ebebeb;
		padding-top: 15px;
		margin-top: 30px;

		h5 {
			font-weight: 700;
			margin-bottom: 25px;
		}

		.description {
			line-height: 10px;

			@media(max-width: 768px) {
				line-height: 24px;
			}
		}

	}


	.unit-book-now-v4 {
		@media(min-width:1200px) {
			background-color: var(--color-grey-light);
			padding: 20px 15px;
			position: -webkit-sticky;
			position: sticky;
			z-index: 12;
			top: 180px;
			margin-bottom: 57px;
		}

	}

}

.vo-unit-body .popover {
	max-width: 290px;
}