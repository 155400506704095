.search-page-layout-v3 {
	background-color: var(--color-white);
	margin-top: 100px;
	/* margin-top: var(--page-margin-top); */

	.inner-banner {
		height: 380px;

		@media (max-width:992px) {
			display: none;
		}
	}

	.search-banner {
		position: relative;
	}

	.header-contant-text {
		text-align: center;
		max-width: 900px;
		margin: 0 auto;
	}

	.margin-top {
		margin-top: 40px;
	}

	.more-btn-panel {
		margin-top: 30px;
	}

	.results {
		&.row {
			margin-left: -5px;
			margin-right: -5px;

			.col-lg-4,
			.col-md-6 {
				padding-left: 5px;
				padding-right: 5px;
			}
		}
	}

	.search-facilities {
		.modal-dialog {
			background-color: var(--color-white);
			padding: 20px;

			.modal-header {
				display: flex;
				flex-shrink: 0;
				align-items: center;
				justify-content: space-between;
				padding: 1rem 1rem;
				border-bottom: 1px solid #dee2e6;
				border-top-left-radius: calc(0.3rem - 1px);
				border-top-right-radius: calc(0.3rem - 1px);
			}
		}
	}

}

.search-tocken {
	@media (max-width:767px) {
		padding-top: 20px;
	}
}

.new-alert-text {
	font-size: 18px;
}

.my-search-token-v2 {
	margin: 2px;
	padding: 2px 6px;
	display: inline-block;
	border: 1px solid var(--color-grey-normal);
	border-radius: 4px;
	background-color: var(--color-grey-light);
	font-size: 14px;
	cursor: pointer;
	position: relative;
	transition: all 0.5s ease;
	margin-left: 8px;

	.close {
		transition: all 0.5s ease;
		position: absolute;
		height: 13px;
		width: 13px;
		right: -7px;
		top: -7px;
		font-size: 14px;
		text-align: center;
		background-color: var(--color-primary);
		color: #fff;
		border-radius: 50%;
		font-weight: 400;
		line-height: 1px;
		padding-bottom: 2px;
		opacity: 0;
	}

	&:hover {
		border: 1px solid var(--color-primary);
		background-color: var(--color-white);

		.close {
			opacity: 1;
		}
	}
}