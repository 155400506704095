.widget-header {
	background-color: var(--color-grey-light);
	padding: 10px;
	margin-bottom: 0;

	font-size: var(--h2-font-size);
	font-weight: var(--h2-font-weight);
	text-decoration: var(--h2-text-decoration);
	text-transform: var(--h2-text-transform);
	color: var(--color-primary);
	font-family: var(--font-family-main);
}

.widget-block {
	background-color: var(--color-grey-light);
	padding: 15px;

	/*border: 1px solid var(--widget-border-color);*/
	@media (max-width:767px) {
		padding: 15px 5px;
	}

	.tags-li {
		li {
			padding: 10px 0;
			border-bottom: 1px solid var(--color-grey-normal);
		}
	}

	.title {
		font-size: var(--h3-font-size);
		font-weight: var(--h3-font-weight);
		text-decoration: var(--h3-text-decoration);
		text-transform: var(--h3-text-transform);
		color: var(--widget-block-title-font-color);
		margin-bottom: 1rem;
	}

	.unit-map {
		width: 100%;
		height: 380px;
		position: relative;
	}
}