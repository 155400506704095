.widget-header-v2 {
	background-color: transparent;
}

.unit-pricelist-v1 {
	padding-top: 10px;
	border-top: 1px solid #ebebeb;
	margin-top: 50px;

	.table {

		td,
		th,
		tbody {
			border: none;
		}

		.first-column {
			/* min-width: 380px; */
			width: 460px;
		}

		.center-column {
			/* min-width: 380px; */
			min-width: 100px;
		}

		.last-column {
			/* min-width: 380px; */
			width: 200px;
		}

	}


	.price-info-text {
		margin-top: 15px;
		margin-bottom: 0;
		font-weight: 600;
	}

	.nav-tabs li {
		flex: none;
	}

	.pricelist {

		.price-info-text {}

		.mainrow {
			td {
				font-weight: 500;
				padding: 0.3rem;

				@media (max-width:420px) {
					font-size: 0.9rem;
				}

			}
		}

		.subrow {
			td {
				vertical-align: bottom;

				@media (max-width:420px) {
					font-size: 0.9rem;
				}
			}
		}
	}

	.price-content {
		/* margin-bottom: 15px; */
		display: inline-block;
		width: 100%;
	}


	@media (max-width: 430px) {
		.table {
			.first-column {
				width: 220px;
			}

			.center-column {
				/* min-width: 380px; */
				min-width: 70px;
			}

			.last-column {
				/* min-width: 380px; */
				width: 100px;
			}
		}

	}

}