.search-unit-v3 {

	.unit-item {
		/* padding: 10px; */
		border-color: var(--color-grey-dark);
		transition: all 0.2s;
		margin-bottom: 20px;

		&:hover {
			/* border: 1px solid var(--color-primary);
			transform: scale(1.015); */

			.owl-carousel {
				.owl-nav {
					button {
						visibility: visible;
						opacity: 1;
					}
				}
			}

			.go-to-unit {
				opacity: 1;
			}
		}

		.owl-carousel {
			overflow: hidden;

			.item {
				img {
					aspect-ratio: 3/2;
				}
			}

			.owl-nav {
				button {
					width: 30px;
					height: 30px;
					top: 50%;
					position: absolute;
					border-radius: 50%;
					cursor: pointer;
					visibility: hidden;
					opacity: 0;
					transform: translate(0, -50%);
					transition: visibility 0s, opacity 0.5s linear;
					color: var(--color-black);
					font-size: 40px;
					line-height: 1px;
					text-align: center;
					background-color: rgba(255, 255, 255, 0.7);
				}

				.owl-prev {
					left: 10px;

					span {
						display: inline-block;
						padding-bottom: 6px;
						padding-right: 1px;
					}
				}

				.owl-next {
					right: 10px;

					span {
						display: inline-block;
						padding-bottom: 6px;
						padding-left: 1px;
					}
				}
			}

			.owl-dots {
				position: absolute;
				width: 70%;
				bottom: 10px;
				padding: 0;
				margin: 0;
				list-style: none;
				text-align: center;
				line-height: 1;
				left: 50%;
				transform: translateX(-50%);

				.owl-dot {
					background-color: #fff;
					width: 6px !important;
					height: 6px !important;
					opacity: .25;
					margin: 0 1px !important;
					border-radius: 50%;

					&.active {
						opacity: 1;
					}
				}
			}
		}

		.unit-container {
			display: flex;
			flex-direction: column;
			height: 100%;
			position: relative;

			.unit-link-bl {
				position: absolute;
				left: 0;
				top: 0;
				right: 0;
				bottom: 0;
				z-index: 1;
			}

			.img-col {
				position: relative;
				width: 100%;
				padding-top: 56.25%;
				aspect-ratio: 3/2;

				.fav-icon {
					position: absolute;
					left: 10px;
					top: 10px;
					color: #fff;
					cursor: pointer;
					z-index: 2;
				}

				.rating {
					position: absolute;
					right: 0px;
					bottom: 0px;
					padding: 7px 7px 7px 12px;
					z-index: 2;
					background-color: rgba(255, 255, 255, 0.7);
					font-size: var(--font-size-md);

					.fa {
						color: var(--color-primary);
					}

					.ratings {
						display: inline-block;
						margin-left: 3px;
					}

				}

				.price {
					position: absolute;
					background-color: var(--color-primary-light);
					text-align: center;
					top: 20px;
					right: -10px;
					padding: 0px 10px;
					z-index: 2;
					color: var(--color-primary);

					.h3 {
						font-size: 18px;
						color: var(--color-primary);
						line-height: 28px;
					}

					.main-price {
						&.h5 {
							color: var(--color-grey-light);
							text-decoration: line-through;

						}
					}


				}

				img {
					width: 100%;
					object-fit: cover;
					height: 100%;
					object-position: center center;
				}

				.img-content {

					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					overflow: hidden;

				}

			}

			.unit-title {
				display: flex;
				justify-content: space-between;
				position: relative;

				h4 {
					padding-top: 10px;
					font-size: 18px;
					color: var(--color-primary);

					span {
						color: var(--font-color-main);
						font-size: 15px;
					}
				}

				.h3 {
					font-size: var(--font-size-main);
				}
			}

			.description {
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				min-height: 38px;
				font-size: 15px;
				line-height: 1.2;
			}

			.type {
				font-size: var(--font-size-md);
			}

			.place {
				font-size: var(--font-size-md);

				.fa {
					color: var(--color-grey-dark);
				}
			}

			.unit-highlights {
				text-align: right;
				position: relative;
				z-index: 2;
				right: 0px;
				top: 5px;

				.fa {
					padding-left: 5px;


				}

				.fa-stack {
					height: 33px !important;
					margin-left: -5px;
					width: 24px !important;
					position: relative;
					top: -2px;
				}
			}

			.flex-middle {
				flex-grow: 1;
				padding: 5px 0px;
			}

			.flex-bottom {
				position: absolute;
				bottom: 0;
				right: 0;
			}

			.properties-row {

				display: flex;

				.unit-properties {
					flex-wrap: wrap;
					display: flex;
					align-items: center;
					font-size: 12px;
					position: relative;
					z-index: 1;

					.unit-prop {
						margin-right: 15px;
					}

					.fa-check {
						font-size: 13px;
					}
				}
			}

			.alternatives {
				padding-top: 5px;
				position: relative;
				z-index: 1;
				display: inline-block;

				.li-bullet {
					margin-bottom: 0;
				}

				.altheading {
					font-weight: 700;
					font-size: 15px;
				}

				a {
					font-size: 15px;
					text-decoration: none;
					color: var(--color-secondary);

					&:hover {
						text-decoration: underline;
					}
				}

				.fa {
					font-size: var(--font-size-sm);
					color: var(--color-secondary);
				}
			}

		}

		.go-to-unit {
			height: 30px;
			display: inline-block;
			width: 30px;
			line-height: 30px;
			text-align: center;
			border-radius: 50%;
			background-color: var(--color-primary);
			transition: all 0.5s;
			opacity: 0;

			.fa {
				color: var(--color-white);
				font-size: 20px;
				line-height: 30px;
			}

		}

		.offer-box {
			position: relative;
			margin-top: 10px;
			color: var(--color-primary);
			background-color: var(--color-secondary-light);
			text-align: center;
			padding: 10px;
			border: 3px dashed #fff;
			font-weight: 700;
		}
	}

}

.popover {
	border-radius: 0;
	background-color: var(--color-white);
	border: 1px solid #C0C0C0;
	font-size: var(--font-size-md);
	box-shadow: inherit;
	width: 100%;
	max-width: 350px;

	.popover-header {
		border-radius: 0;
		background-color: var(--color-grey-light);
		margin-bottom: 10px;
		padding: 10px 10px;
		font-size: var(--font-size-md);
		font-weight: normal;
	}

	.description {
		font-size: 14px;
	}

	.unit-title {
		.h5 {
			font-size: 15px;
		}

		span {
			font-size: 13px;
		}
	}

	.arrow {
		&:after {
			border-top-color: #C0C0C0 !important;
		}
	}

	.popover-body {
		padding: 10px;


		ul {
			padding-right: 10px;


			li {
				position: relative;
				margin-bottom: 2px;
				padding-left: 14px;
				list-style: none;
				font-size: var(--font-size-md);
				font-family: var(--font-family-main);
				margin-bottom: 3px;

				&:before {
					background-color: var(--color-primary);
					width: 5px;
					height: 5px;
					position: absolute;
					content: "";
					left: 0;
					top: 10px;
					border-radius: 50%;

				}
			}
		}
	}
}