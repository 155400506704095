.blogentry-v1 {
	padding-top: var(--page-margin-top);

	.container {
		@media (min-width: 993px) {
			max-width: 80%;
		}

	}

	.img-thumbnail {
		height: 100% !important;
	}

	.date {
		padding-top: 10px;
		font-size: var(--font-size-md);
	}

	.img-thumbnail {
		border: none;
	}



	.img-col {
		position: relative;

		.percent {
			position: absolute;
			left: 0px;
			font-size: 20px;
			font-weight: bold;
			color: var(--color-white);
			background-color: var(--color-red);
			padding: 10px;
		}
	}

	.offer-title {
		padding: 10px 0px;
		font-size: 1.2rem;
		font-weight: bold;
	}

}