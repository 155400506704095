.searchpanel-v2 {
	@media (min-width:993px) {
		margin-top: -40px;
		position: absolute;
		z-index: 5;
		text-align: center;
		margin-bottom: 20px;
		top: 50%;
		transform: translate(-50%, 0);
		left: 50%;


	}

	@media (max-width:992px) {
		margin-top: -100px;
		position: inherit;
		z-index: inherit;
		margin-bottom: 40px;
	}

	.searchbar {
		.text-right {
			.btn-default {
				@media (max-width: 992px) {
					margin-right: 15px;
					margin-top: 18px !important;
				}
			}
		}

		.datepicker-trigger {
			.asd__wrapper {
				@media (max-width: 767px) {
					margin: 0 auto;
				}
			}
		}
	}

	.search-bar-content {
		.btn-sm {
			padding: 6px 16px;
			border-radius: 5px;
			margin-right: 7px;
			font-size: 14px;
		}
	}

	.search-form-area {
		display: -webkit-box;
		display: flex;
		-webkit-box-align: center;
		align-items: center;
		-webkit-box-pack: justify;
		justify-content: space-between;
		box-shadow: 0 0 15px rgb(0 0 0 / 20%);
		background: var(--color-white);
		border-radius: 20px;

		@media (max-width:992px) {
			flex-wrap: wrap;

			top: 0;
			left: 0;
			width: 100%;
			z-index: 50;
			padding: 15px 10px;
			border-radius: 5px;
		}

		.form-control {
			background-color: var(--color-white);
			border-radius: 0;
			width: 100%;
			height: inherit;
			box-shadow: inherit;
			border: none;
			font-size: 1rem;
			font-weight: 400;
			line-height: 1.5;
			color: #495057;
			text-align: left;
			padding-right: 50px;
			padding-left: 0;

			@media (max-width:992px) {
				height: 38px;
				padding-left: 40px;
				top: 5px;
			}

		}
	}


	.form-flex {
		padding: 8px 10px;
		border-right: 1px solid #f4f4f4;
		text-align: left;
		width: 41%;

		label {
			margin: 0;
			color: var(--black);
			font-size: 13px;
			text-transform: uppercase;
			display: block;
			font-weight: 400;
			letter-spacing: 1.5px;
		}

		@media (max-width:992px) {
			width: 100%;
		}

		.form-bx-area {
			position: relative;
			width: 100%;


			@media (max-width:992px) {
				padding-right: 0;
				border-bottom: 2px solid #e5e5e5;
				border-bottom: 2px solid var(--color-grey-normal);
				padding-bottom: 3px;
				margin-bottom: 15px;
			}


			.fa {
				position: absolute;
				top: 8px;
				color: var(--color-black);
				z-index: 2;
				font-size: var(--font-size-main);
				pointer-events: none;
				right: 0;

				@media (max-width:992px) {
					left: 0px;
				}


			}
		}
	}



	.form-btn {


		.btn {
			-webkit-box-align: center;
			align-items: center;
			display: -webkit-box;
			display: flex;
			-webkit-box-pack: center;
			justify-content: center;
			font-size: 18px;
			font-weight: 700;
			height: 74px;
			line-height: 1;
			position: relative;


			i {
				margin-left: 8px;
				font-size: 20px;
				margin-right: 12px;

				@media (max-width:992px) {
					font-size: 16px;
					margin-right: 6px;
				}
			}

			@media (max-width:992px) {
				font-size: 13px;
				position: relative;
				height: 35px;
				padding-right: 15px;
				right: 0;
				border-radius: 0;
			}
		}
	}

	.traveltime {
		@media (min-width:993px) {
			min-width: 170px;
		}
	}


	.filter-btn {
		min-width: 100px;
		padding-left: 20px;
		color: var(--color-primary);
		font-size: var(--font-size-md);
		padding: 8px 10px;
		background-color: var(--color-primary-light);
		border-radius: 0 20px 20px 0;

		@media (max-width:992px) {
			border-radius: 0;
			background-color: var(--color-primary);
		}


		.btn-filter {
			padding-right: 0px;
			margin-right: 3px;
			border: none;
			background-color: transparent;
		}

		@media (min-width: 993px) {



			a {
				color: var(--color-primary);
				text-decoration: none;
				display: inline-flex;
				align-items: center;
				justify-content: center;
				height: 58px;

				&:hover {
					color: var(--color-primary);
					text-decoration: none;
				}
			}

			.btn-filter {
				@media (min-width:993px) {

					/*border-right: 1px solid var(--color-white);*/
					top: 0;
					color: var(--color-black);

					&:hover {
						color: var(--color-primary);
					}

				}
			}
		}

		.spacer {
			color: var(--color-white);

			@media (max-width: 992px) {
				color: var(--color-black);
			}
		}

		@media (max-width: 992px) {

			display: inline-block;
			padding-left: 20px;
			font-size: var(--font-size-md);
			font-weight: 400;

			a {
				color: var(--color-white);
				text-decoration: none;

				&:hover {
					color: var(--color-primary);
					text-decoration: none;
				}
			}

			.btn-filter {
				@media (max-width:992px) {
					/*border-right: 1px solid var(--color-black); */
					top: 0;
					color: var(--color-black);

					&:hover {
						color: var(--color-primary);

					}

				}

			}

		}

	}


	.hidden {
		display: none !important;
	}
}