.static-v1 {
	margin-top: var(--page-margin-top);

	.lingurien-slide {
		.owl-item {
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}

	.unit-lists-li {
		list-style: none;
		column-count: 2;

		@media (min-width:993px) {
			column-count: 3;
		}

		li {
			a {
				padding-left: 30px;
				position: relative;
				display: inline-block;
				color: var(--color-primary);

				@media (max-width:767px) {
					padding-left: 23px;
					font-size: 14px;
				}

				.h5 {
					color: var(--color-primary);

					@media (max-width:767px) {
						font-size: 14px;
					}

					&:hover {
						color: var(--color-black);
					}
				}

				.region-name {
					font-size: 13px;
					color: #a5a5a5;
				}



				&:before {
					content: "\f015";
					padding-right: 0.7em;
					color: #22a76a;
					font-family: FontAwesome;
					position: absolute;
					left: 0;
					top: 6px;
					line-height: 1;

					@media (max-width:767px) {
						top: 4px;
					}
				}
			}
		}
	}

	.unitsAktuelles-section {
		.single-unit {
			position: unset;
			visibility: visible;
			opacity: 1;
			max-width: 100%;
			height: 100%;

			.unit-card-v2 {
				display: flex;
				flex-direction: column;
				height: 100%;

				.content {
					height: 100%;
				}
			}
		}
	}
}